.footer {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;

    @include onNotebook {
      padding-top: 40px;
      padding-bottom: 24px;
    }
  }

  &__certificates {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: auto;

    @include onNotebook {
      flex-direction: row;
      margin-bottom: 85px;

      gap: 24px;
    }

    &-1 {
      width: 286px;
      height: 204px;
      border-radius: 16px;
      margin-bottom: 24px;
      box-shadow: 0px 12.782122611999512px 19.17318344116211px -12.782122611999512px #3B383840;
      cursor: default;

      @include onNotebook {
        width: 411px;
        height: 294px;

        border-radius: 23px;
        margin-bottom: 0;
      }
    }

    &-2 {
      width: 231px;
      height: 164px;
      border-radius: 16px;
      margin-bottom: 64px;
      box-shadow: 0px 10.32402229309082px 15.48603343963623px -10.32402229309082px #3B383840;
      cursor: default;

      @include onNotebook {
        width: 411px;
        height: 294px;

        border-radius: 23px;
        margin-bottom: 0px;
      }
    }
  }

  &__logo {
    margin-bottom: 64px;
    text-decoration: none;
    cursor: pointer;

    @include onNotebook {
      margin-bottom: 84px;

      transition: transform 0.4s;

      &:hover {
        transform: scale(1.1);
      }

      width: 108px;
      height: 72px;
    }

    &-text {
      @include onNotebook {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0.16em;
        text-align: center;
      }
    }

    &-image {
      cursor: pointer;
      @include onNotebook {
        width: 72px;
        height: 52px;
      }
    }
  }

  &__contacts {
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: space-between;

    @include onNotebook {
      justify-content: space-between;
    }

    &-licence {
      display: flex;
      align-items: center;
      font-family: 'Ledger', 'Times New Roman', Times, serif;
      font-size: 8px;
      font-weight: 400;
      line-height: 11px;
      text-align: left;

      @include onNotebook {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        margin-right: 40px
      }

      &-icon {
        @include square(8px);
        margin-right: 2px;

        @include onNotebook {
          @include square(22px)
        }
      }
    }

    &-icons {
      width: 80px;
      height: 32px;
      position: relative;
      &-instagram {
        @include square(32px);
        margin-right: 16px;
        transition: transform 0.4s;

        @include onNotebook {
          @include square(40px);

          &:hover {
            transform: scale(1.1);
          }
        }
      }

      &-telegram {
        @include square(32px);
        transition: transform 0.4s;

        @include onNotebook {
          @include square(40px);

          &:hover {
            transform: scale(1.1);
          }
        }
      }

      @include onNotebook {
        margin-right: 0px;
        height: 40px;
        width: 96px;
      }
    }

    &-designer {
      font-family: 'Ledger', 'Times New Roman', Times, serif;
      font-size: 8px;
      font-weight: 400;
      line-height: 11px;
      text-align: left;
      text-transform: uppercase;
      color: #40392A;

      @include onNotebook {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0em;
        text-decoration: underline;
        transition: all 0.4s;

        &:hover {
          color: $c_header;
        }
      }
    }    
  }

  &__link-instagram {
    position: absolute;
    top: 0;
    left: 0;

    @include square(32px);

    @include onNotebook {
      @include square(40px)
    }
  }

  &__link-telegram {
    position: absolute;
    top: 0;
    right: 0;

    @include square(32px);

    @include onNotebook {
      @include square(40px)
    }
  }
}
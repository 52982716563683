a,
img,
svg,
button {
  cursor: pointer;
}

p,
h1,
h2,
h3,
h4,
h5,
div {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

h1, h2, h3, h4, h5, h6,
p, span, a, li, td, th {
  color: #2B2925;
}

@media (prefers-color-scheme: dark) {
  h1, h2, h3, h4, h5, h6,
  p, span, a, li, td, th {
    color: #2B2925;
  }
}

.noSelect {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.noSelect:focus {
  outline: none !important;
}
.acquaintance {  
  position: relative;
  &__header {
    margin: 64px auto 32px;
    &-left-line,
    &-right-line {
      top: 84.5px;
    }

    @include onNotebook {
      margin: 120px auto 64px;

      &-left-line,
      &-right-line {
        top: 165px;
      }
    }
  }

  &__text {
    width: 358px;
    height: 315px;

    margin-bottom: 34px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    font-family: 'Ledger', 'Times New Roman', serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.04em;

    @include onNotebook {
      font-size: 24px;
      line-height: 36px;
      width: 100%;
      height: 468px;

      margin-bottom: 64px;
    }
  }

  &__paragraph {
    text-align: center;

    &--mobile {
      display: block;
      visibility: visible;

      @include onNotebook {
        display: none;
        visibility: hidden;
      }
    }

    &--desktop {
      display: none;
      visibility: hidden;

      @include onNotebook {
        display: block;
        visibility: visible;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
*, *:after, *:before {
  box-sizing: border-box;
}

body,
p,
input,
h1,
ul,
li,
h4,
h2,
h5,
h3 {
  margin: 0;
  padding: 0;
}
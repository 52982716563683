.container {
  margin: 0 auto;

  @include onMobile {
    padding: 0 $padding-mobile;
    max-width: $max-width-mobile;
  }

  @include onNotebook {
    padding: 0 $padding-notebook;
    max-width: $max-width-notebook;
  }
}
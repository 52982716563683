.header {
  background-image: url("../images/main-photo.jpg");
  background-position-x: 50%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media screen and (min-width: 1024px) and (max-width: 1200px) {
    background-position: 65% -25px;
  }

  @media screen and (min-width: 1200.5px) and (max-width: 1385px) {
    background-position: 74% -25px;
  }

  @media screen and (min-width: 1385.5px) {
    background-position: 98% -25px;
  }

  height: 100vh;

  &__top-actions {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;

    background-color: #ffffff99;
  }

  &__text {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;

    background-image: linear-gradient(to right, #EBE9EA, #f9f9f9);

    cursor: default;
    font-family: 'Cormorant Infant', serif;
    text-align: center;
    height: 93px;
    letter-spacing: 0.12em;

    @media screen and (min-height: 860px) {
      height: 110px;
    }

    @include onNotebook {
      height: 104px;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    font-style: italic;
    color: $c_header;

    padding: 16px 0 8px;
    @include onHighScreen {
      padding: 20px 0 10px;
    }
    margin: 0 auto;

    width: 285px;

    @include onNotebook {
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.12em;

      width: 780px;
      padding: 24px 0 16px;
    }
  }

  &__subtitle {
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
    font-style: italic;
    color: $c_header;
    
    @include onNotebook {
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.12em;
    }
  }
}
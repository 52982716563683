.astro-help {
  position: relative;
  height: 522px;

  @media screen and (min-width: 488px) and (max-width: 899.5px) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  @include onNotebook {
    height: 772px;
  }

  @media screen and (min-width: 900.5px) and (max-width: 1023.5px) {
    height: 960px;
  }

  &__container {
    @media screen and (min-width: 450.5px) {
      padding-left: 0;
    }
  }

  &__header {
    text-align: center;
    margin-bottom: 16px;
    &-left-line,
    &-right-line {
      top: 20.5px
    }

    @include onNotebook {
      margin-bottom: 64px;

      &-left-line,
      &-right-line {
        top: 41.5px
      }
    }
  }

  &__content-wrapper {
    display: flex;
    gap: 6px;
    
    @include onNotebook {
      gap: 24px;
    }

    @media screen and (min-width: 480px) and (max-width: 900px) {
      justify-content: center;
      margin-right: -100px;
    }

    @media screen and (min-width: 1370px) {
      justify-content: center;
      padding: 0 80px;
    }
  }

  &__calender {
    background-image: url(../images/calender-mobile.png);
    background-size: cover;
    background-position: 92% 17px;
    background-repeat: no-repeat;
    width: 336px;
    height: 400px;
    margin: 16px 0 32px -197px;
    transform: scale(1.1);

    @media screen and (min-width: 488px) and (max-width: 899.5px) {
      margin: 9px 0 78px -197px;
      height: 322px;
    }

    @include onNotebook {
      background-image: url("../images/calender-desktop.png");
      background-position: 100%;
      transform: scale(1);
      width: 628;
      height: 624;
      margin: 0;
    }

    @media screen and (min-width: 900.5px) and (max-width: 1021.5px) {
      margin-top: 90px;
    }

    @media screen and (min-width: 1022px) and (max-width: 1105.5px) {
      margin-top: 19px;
    }

    @media screen and (min-width: 1105.5px) and (max-width: 1160.5px) {
      margin-top: 50px;
    }

    @media screen and (min-width: 1182.5px) and (max-width: 1200.5px) {
      margin-top: 20px;
    }
  }

  &__list {
    height: 362px;
    padding-top: 51px;
    width: 60%;

    @media screen and (min-width: 488px) and (max-width: 899.5px) {
      padding-top: 67px;
    }

    font-family: 'Ledger', 'Times New Roman', Times, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.04em;
    text-align: left;

    @include onNotebook {
      width: 628px;
      height: 624px;

      font-size: 24px;
      line-height: 36px;

      padding: 90px 0;
    }
  }

  &__item {
    display: block;

    margin-bottom: 16px;

    @include onNotebook {
      margin-bottom: 32px;
    }

    &--2,
    &--6 {
      margin-left: 16px;

      @include onNotebook {
        margin-left: 40px;
      }
    }

    &--3, 
    &--5 {
      margin-left: 32px;

      @include onNotebook {
        margin-left: 80px;
      }
    }

    &--4 {
      margin-left: 48px;

      @include onNotebook {
        margin-left: 104px;
      }
    }
  }
}
*, :after, :before {
  box-sizing: border-box;
}

body, p, input, h1, ul, li, h4, h2, h5, h3 {
  margin: 0;
  padding: 0;
}

a, img, svg, button {
  cursor: pointer;
}

p, h1, h2, h3, h4, h5, div {
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
}

h1, h2, h3, h4, h5, h6, p, span, a, li, td, th {
  color: #2b2925;
}

@media (prefers-color-scheme: dark) {
  h1, h2, h3, h4, h5, h6, p, span, a, li, td, th {
    color: #2b2925;
  }
}

.noSelect {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

.noSelect:focus {
  outline: none !important;
}

@keyframes createCross {
  from {
    transform: rotateZ(0);
  }

  to {
    transform: rotateZ(45deg);
  }
}

.first {
  transition: all .4s;
  transform: translateX(642px);
}

.second {
  transition: all .4s;
  transform: translateX(424px);
}

.third {
  transition: all .4s;
  transform: translateX(-191px);
}

.thirdRight {
  transition: all .4s;
  transform: translateX(191px);
}

.fourth {
  transition: all .4s;
  transform: translateX(-423px);
}

.fifth {
  transition: all .4s;
  transform: translateX(-653px);
}

.firstPaginator {
  transition: all .4s;
  transform: translateX(0);
}

.secondPaginator {
  transition: all .4s;
  transform: translateX(-30px);
}

.thirdPaginator {
  transition: all .4s;
  transform: translateX(-60px);
}

.fourthPaginator {
  transition: all .4s;
  transform: translateX(-90px);
}

.fifthPaginator {
  transition: all .4s;
  transform: translateX(-120px);
}

.firstFeedback {
  transition: all .4s;
  transform: translateX(860px);
}

.secondFeedback {
  transition: all .4s;
  transform: translateX(430px);
}

.thirdFeedback {
  transition: all .4s;
  transform: translateX(0);
}

.fourthFeedback {
  transition: all .4s;
  transform: translateX(-430px);
}

.fifthFeedback {
  transition: all .4s;
  transform: translateX(-860px);
}

.firstSlide {
  transition: all .4s;
  transform: translateX(460px)scale(1.97);
}

.secondSlide {
  transition: all .4s;
  transform: translateX(232px)scale(1.97);
}

.thirdSlide {
  transition: all .4s;
  transform: translateX(0)scale(1.97);
}

.fourthSlide {
  transition: all .4s;
  transform: translateX(-232px)scale(1.97);
}

.fifthSlide {
  transition: all .4s;
  transform: translateX(-460px)scale(1.97);
}

.firstSlidePrev {
  transition: all .4s;
  transform: translateX(86px)scale(1.44);
}

.secondSlidePrev {
  transition: all .4s;
  transform: translateX(-146px)scale(1.44);
}

.thirdSlidePrev {
  transition: all .4s;
  transform: translateX(-378px)scale(1.44);
}

.fourthSlidePrev {
  transition: all .4s;
  transform: translateX(-608px)scale(1.44);
}

.secondSlideNext {
  transition: all .4s;
  transform: translateX(606px)scale(1.44);
}

.thirdSlideNext {
  transition: all .4s;
  transform: translateX(378px)scale(1.44);
}

.fourthSlideNext {
  transition: all .4s;
  transform: translateX(146px)scale(1.44);
}

.fifthSlideNext {
  transition: all .4s;
  transform: translateX(-86px)scale(1.44);
}

.page {
  scroll-behavior: smooth;
  color: #2b2925;
  font-family: Cormorant Infant, Georgia, Times New Roman, serif;
  position: relative;
}

.page__body {
  background-image: url("background-mobile.58a87931.jpg");
  background-position: 0 94.5vh;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .page__body {
    background-image: url("background-desktop.770e65d3.jpg");
    background-position: 0 100vh;
  }
}

.page__body--with-menu {
  overflow: hidden;
}

.page__menu {
  transition: transform .4s;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: translateX(-100%);
}

.page__menu--opened {
  transform: translateX(0);
}

.page__astro-help {
  margin-bottom: 12px;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .page__astro-help {
    margin-bottom: 120px;
  }
}

.page__nav {
  position: absolute;
  top: 0;
  right: 0;
}

.page::-webkit-scrollbar {
  width: 0;
}

.page::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.page::-webkit-scrollbar-button:start:decrement {
  display: none;
}

.page::-webkit-scrollbar-button:end:increment {
  display: none;
}

.page::-webkit-scrollbar-track {
  background-color: #0000;
}

.container {
  margin: 0 auto;
}

@media screen and (width >= 320px) and (width <= 899.5px) {
  .container {
    max-width: 390px;
    padding: 0 16px;
  }
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .container {
    max-width: 1440px;
    padding: 0 80px;
  }

  .grid {
    grid-template-columns: repeat(12, 98px);
    column-gap: 24px;
  }
}

.grid__item--1-1 {
  grid-column: 1 / 2;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-1-1 {
    grid-column: 1 / 2;
  }
}

.grid__item--1-2 {
  grid-column: 1 / 3;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-1-2 {
    grid-column: 1 / 3;
  }
}

.grid__item--1-3 {
  grid-column: 1 / 4;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-1-3 {
    grid-column: 1 / 4;
  }
}

.grid__item--1-4 {
  grid-column: 1 / 5;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-1-4 {
    grid-column: 1 / 5;
  }
}

.grid__item--1-5 {
  grid-column: 1 / 6;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-1-5 {
    grid-column: 1 / 6;
  }
}

.grid__item--1-6 {
  grid-column: 1 / 7;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-1-6 {
    grid-column: 1 / 7;
  }
}

.grid__item--1-7 {
  grid-column: 1 / 8;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-1-7 {
    grid-column: 1 / 8;
  }
}

.grid__item--1-8 {
  grid-column: 1 / 9;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-1-8 {
    grid-column: 1 / 9;
  }
}

.grid__item--1-9 {
  grid-column: 1 / 10;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-1-9 {
    grid-column: 1 / 10;
  }
}

.grid__item--1-10 {
  grid-column: 1 / 11;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-1-10 {
    grid-column: 1 / 11;
  }
}

.grid__item--1-11 {
  grid-column: 1 / 12;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-1-11 {
    grid-column: 1 / 12;
  }
}

.grid__item--1-12 {
  grid-column: 1 / 13;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-1-12 {
    grid-column: 1 / 13;
  }
}

.grid__item--2-2 {
  grid-column: 2 / 3;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-2-2 {
    grid-column: 2 / 3;
  }
}

.grid__item--2-3 {
  grid-column: 2 / 4;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-2-3 {
    grid-column: 2 / 4;
  }
}

.grid__item--2-4 {
  grid-column: 2 / 5;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-2-4 {
    grid-column: 2 / 5;
  }
}

.grid__item--2-5 {
  grid-column: 2 / 6;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-2-5 {
    grid-column: 2 / 6;
  }
}

.grid__item--2-6 {
  grid-column: 2 / 7;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-2-6 {
    grid-column: 2 / 7;
  }
}

.grid__item--2-7 {
  grid-column: 2 / 8;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-2-7 {
    grid-column: 2 / 8;
  }
}

.grid__item--2-8 {
  grid-column: 2 / 9;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-2-8 {
    grid-column: 2 / 9;
  }
}

.grid__item--2-9 {
  grid-column: 2 / 10;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-2-9 {
    grid-column: 2 / 10;
  }
}

.grid__item--2-10 {
  grid-column: 2 / 11;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-2-10 {
    grid-column: 2 / 11;
  }
}

.grid__item--2-11 {
  grid-column: 2 / 12;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-2-11 {
    grid-column: 2 / 12;
  }
}

.grid__item--2-12 {
  grid-column: 2 / 13;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-2-12 {
    grid-column: 2 / 13;
  }
}

.grid__item--3-3 {
  grid-column: 3 / 4;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-3-3 {
    grid-column: 3 / 4;
  }
}

.grid__item--3-4 {
  grid-column: 3 / 5;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-3-4 {
    grid-column: 3 / 5;
  }
}

.grid__item--3-5 {
  grid-column: 3 / 6;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-3-5 {
    grid-column: 3 / 6;
  }
}

.grid__item--3-6 {
  grid-column: 3 / 7;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-3-6 {
    grid-column: 3 / 7;
  }
}

.grid__item--3-7 {
  grid-column: 3 / 8;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-3-7 {
    grid-column: 3 / 8;
  }
}

.grid__item--3-8 {
  grid-column: 3 / 9;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-3-8 {
    grid-column: 3 / 9;
  }
}

.grid__item--3-9 {
  grid-column: 3 / 10;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-3-9 {
    grid-column: 3 / 10;
  }
}

.grid__item--3-10 {
  grid-column: 3 / 11;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-3-10 {
    grid-column: 3 / 11;
  }
}

.grid__item--3-11 {
  grid-column: 3 / 12;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-3-11 {
    grid-column: 3 / 12;
  }
}

.grid__item--3-12 {
  grid-column: 3 / 13;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-3-12 {
    grid-column: 3 / 13;
  }
}

.grid__item--4-4 {
  grid-column: 4 / 5;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-4-4 {
    grid-column: 4 / 5;
  }
}

.grid__item--4-5 {
  grid-column: 4 / 6;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-4-5 {
    grid-column: 4 / 6;
  }
}

.grid__item--4-6 {
  grid-column: 4 / 7;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-4-6 {
    grid-column: 4 / 7;
  }
}

.grid__item--4-7 {
  grid-column: 4 / 8;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-4-7 {
    grid-column: 4 / 8;
  }
}

.grid__item--4-8 {
  grid-column: 4 / 9;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-4-8 {
    grid-column: 4 / 9;
  }
}

.grid__item--4-9 {
  grid-column: 4 / 10;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-4-9 {
    grid-column: 4 / 10;
  }
}

.grid__item--4-10 {
  grid-column: 4 / 11;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-4-10 {
    grid-column: 4 / 11;
  }
}

.grid__item--4-11 {
  grid-column: 4 / 12;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-4-11 {
    grid-column: 4 / 12;
  }
}

.grid__item--4-12 {
  grid-column: 4 / 13;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-4-12 {
    grid-column: 4 / 13;
  }
}

.grid__item--5-5 {
  grid-column: 5 / 6;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-5-5 {
    grid-column: 5 / 6;
  }
}

.grid__item--5-6 {
  grid-column: 5 / 7;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-5-6 {
    grid-column: 5 / 7;
  }
}

.grid__item--5-7 {
  grid-column: 5 / 8;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-5-7 {
    grid-column: 5 / 8;
  }
}

.grid__item--5-8 {
  grid-column: 5 / 9;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-5-8 {
    grid-column: 5 / 9;
  }
}

.grid__item--5-9 {
  grid-column: 5 / 10;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-5-9 {
    grid-column: 5 / 10;
  }
}

.grid__item--5-10 {
  grid-column: 5 / 11;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-5-10 {
    grid-column: 5 / 11;
  }
}

.grid__item--5-11 {
  grid-column: 5 / 12;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-5-11 {
    grid-column: 5 / 12;
  }
}

.grid__item--5-12 {
  grid-column: 5 / 13;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-5-12 {
    grid-column: 5 / 13;
  }
}

.grid__item--6-6 {
  grid-column: 6 / 7;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-6-6 {
    grid-column: 6 / 7;
  }
}

.grid__item--6-7 {
  grid-column: 6 / 8;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-6-7 {
    grid-column: 6 / 8;
  }
}

.grid__item--6-8 {
  grid-column: 6 / 9;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-6-8 {
    grid-column: 6 / 9;
  }
}

.grid__item--6-9 {
  grid-column: 6 / 10;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-6-9 {
    grid-column: 6 / 10;
  }
}

.grid__item--6-10 {
  grid-column: 6 / 11;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-6-10 {
    grid-column: 6 / 11;
  }
}

.grid__item--6-11 {
  grid-column: 6 / 12;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-6-11 {
    grid-column: 6 / 12;
  }
}

.grid__item--6-12 {
  grid-column: 6 / 13;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-6-12 {
    grid-column: 6 / 13;
  }
}

.grid__item--7-7 {
  grid-column: 7 / 8;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-7-7 {
    grid-column: 7 / 8;
  }
}

.grid__item--7-8 {
  grid-column: 7 / 9;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-7-8 {
    grid-column: 7 / 9;
  }
}

.grid__item--7-9 {
  grid-column: 7 / 10;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-7-9 {
    grid-column: 7 / 10;
  }
}

.grid__item--7-10 {
  grid-column: 7 / 11;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-7-10 {
    grid-column: 7 / 11;
  }
}

.grid__item--7-11 {
  grid-column: 7 / 12;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-7-11 {
    grid-column: 7 / 12;
  }
}

.grid__item--7-12 {
  grid-column: 7 / 13;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-7-12 {
    grid-column: 7 / 13;
  }
}

.grid__item--8-8 {
  grid-column: 8 / 9;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-8-8 {
    grid-column: 8 / 9;
  }
}

.grid__item--8-9 {
  grid-column: 8 / 10;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-8-9 {
    grid-column: 8 / 10;
  }
}

.grid__item--8-10 {
  grid-column: 8 / 11;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-8-10 {
    grid-column: 8 / 11;
  }
}

.grid__item--8-11 {
  grid-column: 8 / 12;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-8-11 {
    grid-column: 8 / 12;
  }
}

.grid__item--8-12 {
  grid-column: 8 / 13;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-8-12 {
    grid-column: 8 / 13;
  }
}

.grid__item--9-9 {
  grid-column: 9 / 10;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-9-9 {
    grid-column: 9 / 10;
  }
}

.grid__item--9-10 {
  grid-column: 9 / 11;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-9-10 {
    grid-column: 9 / 11;
  }
}

.grid__item--9-11 {
  grid-column: 9 / 12;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-9-11 {
    grid-column: 9 / 12;
  }
}

.grid__item--9-12 {
  grid-column: 9 / 13;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-9-12 {
    grid-column: 9 / 13;
  }
}

.grid__item--10-10 {
  grid-column: 10 / 11;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-10-10 {
    grid-column: 10 / 11;
  }
}

.grid__item--10-11 {
  grid-column: 10 / 12;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-10-11 {
    grid-column: 10 / 12;
  }
}

.grid__item--10-12 {
  grid-column: 10 / 13;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-10-12 {
    grid-column: 10 / 13;
  }
}

.grid__item--11-11 {
  grid-column: 11 / 12;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-11-11 {
    grid-column: 11 / 12;
  }
}

.grid__item--11-12 {
  grid-column: 11 / 13;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-11-12 {
    grid-column: 11 / 13;
  }
}

.grid__item--12-12 {
  grid-column: 12 / 13;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .grid__item_desktop-12-12 {
    grid-column: 12 / 13;
  }
}

.nav {
  visibility: hidden;
  display: none;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .nav {
    visibility: visible;
    display: block;
  }
}

.nav__list {
  letter-spacing: .08em;
  text-align: left;
  justify-content: center;
  align-items: center;
  gap: 40px;
  height: 87px;
  font-family: Cormorant Infant;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  list-style-type: none;
  display: flex;
}

.nav__link {
  border-bottom: 1px solid #0000;
  padding-bottom: 5px;
  text-decoration: none;
  transition: all .4s;
}

.nav__link:hover {
  font-family: Cormorant Infant;
  font-weight: 700;
}

.nav__item {
  transition: all .4s;
}

.nav__item:hover {
  transform: scale(1.2);
}

.burger-menu {
  cursor: pointer;
  z-index: 2;
  flex-direction: column;
  width: 40px;
  height: 40px;
  padding: 8px;
  display: flex;
  position: relative;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .burger-menu {
    visibility: hidden;
    display: none;
  }
}

.burger-menu__opener {
  opacity: 0;
  z-index: 3;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
}

.burger-menu__opener[type="checkbox"]:checked ~ span:first-of-type {
  opacity: 0;
  transition: all .4s;
}

.burger-menu__opener[type="checkbox"]:checked ~ span:nth-of-type(2), .burger-menu__opener[type="checkbox"]:not(:checked) ~ span:first-of-type {
  opacity: 1;
  transition: all .4s;
}

.burger-menu__opener[type="checkbox"]:not(:checked) ~ span:nth-of-type(2) {
  opacity: 0;
  transition: all .4s;
}

.burger-menu__cross {
  opacity: 0;
  background-image: url("cross.7cb1d667.svg");
  background-position: center;
  width: 24px;
  height: 24px;
  position: absolute;
}

.burger-menu__burger {
  opacity: 1;
  background-image: url("burger.2402b75d.svg");
  background-position: center;
  width: 24px;
  height: 24px;
  position: absolute;
}

.header {
  background-image: url("main-photo.0d0f00da.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: relative;
}

@media screen and (width >= 1024px) and (width <= 1200px) {
  .header {
    background-position: 65% -25px;
  }
}

@media screen and (width >= 1200.5px) and (width <= 1385px) {
  .header {
    background-position: 74% -25px;
  }
}

@media screen and (width >= 1385.5px) {
  .header {
    background-position: 98% -25px;
  }
}

.header__top-actions {
  background-color: #fff9;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.header__text {
  cursor: default;
  text-align: center;
  letter-spacing: .12em;
  background-image: linear-gradient(to right, #ebe9ea, #f9f9f9);
  height: 93px;
  font-family: Cormorant Infant, serif;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media screen and (height >= 860px) {
  .header__text {
    height: 110px;
  }
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .header__text {
    height: 104px;
  }
}

.header__title {
  color: #987838;
  width: 285px;
  margin: 0 auto;
  padding: 16px 0 8px;
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  line-height: 19px;
}

@media screen and (height >= 870px) {
  .header__title {
    padding: 20px 0 10px;
  }
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .header__title {
    letter-spacing: .12em;
    width: 780px;
    padding: 24px 0 16px;
    font-size: 24px;
    line-height: 29px;
  }
}

.header__subtitle {
  color: #987838;
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  line-height: 15px;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .header__subtitle {
    letter-spacing: .12em;
    font-size: 16px;
    line-height: 19px;
  }
}

.menu {
  z-index: 1;
  background-image: url("fluid-art-swirl-acrylic-paint.07c9db4d.jpg");
  background-position: -59px bottom;
  background-size: 580px 921px;
  height: 100vh;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .menu {
    visibility: hidden;
    display: none;
  }
}

.menu__content {
  margin-top: 28vh;
}

.menu__list {
  letter-spacing: .16em;
  text-align: center;
  width: 108px;
  height: 172px;
  margin: 0 auto 80px;
  font-family: Cormorant Infant, Times New Roman, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  list-style: none;
}

.menu__item {
  margin-bottom: 32px;
}

.menu__item:last-child {
  margin-bottom: 0;
}

.menu__link {
  color: #2b2925;
  text-decoration: none;
}

.menu__social-networks {
  margin: 0 auto 176px;
}

.menu__logo {
  position: absolute;
  bottom: -2px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
}

.top-actions {
  justify-content: space-between;
  align-items: center;
  height: 87px;
  padding: 16px;
  display: flex;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .top-actions {
    padding: 16px 80px;
  }
}

.logo {
  flex-direction: column;
  align-items: center;
  width: 83px;
  height: 55px;
  display: flex;
}

.logo__image {
  cursor: default;
  width: 56px;
  height: 40px;
}

.logo__text {
  letter-spacing: .16em;
  text-align: center;
  text-transform: uppercase;
  font-family: Cormorant Infant, Times New Roman, serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
}

.social-networks {
  justify-content: space-between;
  width: 80px;
  height: 32px;
  display: flex;
}

.social-networks__instagram, .social-networks__telegram {
  width: 32px;
  height: 32px;
}

.section-header {
  margin: 64px auto 32px;
}

.section-header-text {
  letter-spacing: .04em;
  font-family: Cormorant Infant;
  font-size: 28px;
  font-weight: 600;
  line-height: 42px;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .section-header-text {
    font-size: 56px;
    line-height: 84px;
  }
}

.section-header-left-line, .section-header-right-line {
  border: .5px solid #000;
  width: 40px;
  position: absolute;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .section-header-left-line, .section-header-right-line {
    width: 200px;
  }
}

@media screen and (width >= 564px) and (width <= 899.5px) {
  .section-header-left-line, .section-header-right-line {
    width: 100px;
  }
}

@media screen and (width >= 900px) and (width <= 970px) {
  .section-header-left-line, .section-header-right-line {
    width: 150px;
  }
}

.section-header-left-line {
  left: 0;
}

.section-header-right-line {
  right: 0;
}

.dropdown {
  border-bottom: 1px solid;
  border-image-source: linear-gradient(#f7eedb 0%, #dfcba3 100%);
  border-image-slice: 1;
  border-image-repeat: stretch;
  height: 72px;
  position: relative;
}

.dropdown__button {
  opacity: 0;
  position: absolute;
}

.dropdown__text {
  letter-spacing: 0;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 4px;
  padding-top: 24px;
  font-family: Cormorant Infant, Times New Roman, Times, serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.dropdown__arrow-wrapper {
  width: 16px;
  margin: auto;
}

.acquaintance {
  position: relative;
}

.acquaintance__header {
  margin: 64px auto 32px;
}

.acquaintance__header-left-line, .acquaintance__header-right-line {
  top: 84.5px;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .acquaintance__header {
    margin: 120px auto 64px;
  }

  .acquaintance__header-left-line, .acquaintance__header-right-line {
    top: 165px;
  }
}

.acquaintance__text {
  letter-spacing: .04em;
  flex-direction: column;
  justify-content: space-between;
  width: 358px;
  height: 315px;
  margin-bottom: 34px;
  font-family: Ledger, Times New Roman, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: flex;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .acquaintance__text {
    width: 100%;
    height: 468px;
    margin-bottom: 64px;
    font-size: 24px;
    line-height: 36px;
  }
}

.acquaintance__paragraph {
  text-align: center;
}

.acquaintance__paragraph--mobile {
  visibility: visible;
  display: block;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .acquaintance__paragraph--mobile {
    visibility: hidden;
    display: none;
  }
}

.acquaintance__paragraph--desktop {
  visibility: hidden;
  display: none;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .acquaintance__paragraph--desktop {
    visibility: visible;
    display: block;
  }
}

.acquaintance__content {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.main-content {
  overflow: hidden;
}

.main-content__section {
  margin-bottom: 64px;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .main-content__section {
    margin-bottom: 120px;
  }
}

.astro-help {
  height: 522px;
  position: relative;
}

@media screen and (width >= 488px) and (width <= 899.5px) {
  .astro-help {
    justify-content: center;
    align-items: center;
    height: 100%;
    display: flex;
  }
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .astro-help {
    height: 772px;
  }
}

@media screen and (width >= 900.5px) and (width <= 1023.5px) {
  .astro-help {
    height: 960px;
  }
}

@media screen and (width >= 450.5px) {
  .astro-help__container {
    padding-left: 0;
  }
}

.astro-help__header {
  text-align: center;
  margin-bottom: 16px;
}

.astro-help__header-left-line, .astro-help__header-right-line {
  top: 20.5px;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .astro-help__header {
    margin-bottom: 64px;
  }

  .astro-help__header-left-line, .astro-help__header-right-line {
    top: 41.5px;
  }
}

.astro-help__content-wrapper {
  gap: 6px;
  display: flex;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .astro-help__content-wrapper {
    gap: 24px;
  }
}

@media screen and (width >= 480px) and (width <= 900px) {
  .astro-help__content-wrapper {
    justify-content: center;
    margin-right: -100px;
  }
}

@media screen and (width >= 1370px) {
  .astro-help__content-wrapper {
    justify-content: center;
    padding: 0 80px;
  }
}

.astro-help__calender {
  background-image: url("calender-mobile.f0997577.png");
  background-position: 92% 17px;
  background-repeat: no-repeat;
  background-size: cover;
  width: 336px;
  height: 400px;
  margin: 16px 0 32px -197px;
  transform: scale(1.1);
}

@media screen and (width >= 488px) and (width <= 899.5px) {
  .astro-help__calender {
    height: 322px;
    margin: 9px 0 78px -197px;
  }
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .astro-help__calender {
    background-image: url("calender-desktop.91ff908f.png");
    background-position: 100%;
    width: 628px;
    height: 624px;
    margin: 0;
    transform: scale(1);
  }
}

@media screen and (width >= 900.5px) and (width <= 1021.5px) {
  .astro-help__calender {
    margin-top: 90px;
  }
}

@media screen and (width >= 1022px) and (width <= 1105.5px) {
  .astro-help__calender {
    margin-top: 19px;
  }
}

@media screen and (width >= 1105.5px) and (width <= 1160.5px) {
  .astro-help__calender {
    margin-top: 50px;
  }
}

@media screen and (width >= 1182.5px) and (width <= 1200.5px) {
  .astro-help__calender {
    margin-top: 20px;
  }
}

.astro-help__list {
  letter-spacing: .04em;
  text-align: left;
  width: 60%;
  height: 362px;
  padding-top: 51px;
  font-family: Ledger, Times New Roman, Times, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}

@media screen and (width >= 488px) and (width <= 899.5px) {
  .astro-help__list {
    padding-top: 67px;
  }
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .astro-help__list {
    width: 628px;
    height: 624px;
    padding: 90px 0;
    font-size: 24px;
    line-height: 36px;
  }
}

.astro-help__item {
  margin-bottom: 16px;
  display: block;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .astro-help__item {
    margin-bottom: 32px;
  }
}

.astro-help__item--2, .astro-help__item--6 {
  margin-left: 16px;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .astro-help__item--2, .astro-help__item--6 {
    margin-left: 40px;
  }
}

.astro-help__item--3, .astro-help__item--5 {
  margin-left: 32px;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .astro-help__item--3, .astro-help__item--5 {
    margin-left: 80px;
  }
}

.astro-help__item--4 {
  margin-left: 48px;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .astro-help__item--4 {
    margin-left: 104px;
  }
}

.services {
  cursor: default;
  position: relative;
}

.services__header {
  width: 100px;
  margin: 0 auto 32px;
}

.services__header-right-line, .services__header-left-line {
  top: 20.5px;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .services__header {
    width: 200px;
    margin-bottom: 64px;
  }

  .services__header-right-line, .services__header-left-line {
    top: 41.5px;
  }
}

.services__list {
  flex-direction: column;
  gap: 16px;
  margin-bottom: 36px;
  display: flex;
}

.services__list--mobile {
  display: block;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .services__list--mobile {
    visibility: hidden;
    display: none;
  }
}

.services__item-content {
  height: 0;
  transition: height 1s;
  overflow: hidden;
}

.services__item-background {
  background-clip: content-box;
  height: 0;
  margin-top: 16px;
  padding: 24px 16px 8px;
  transition: height 1s;
  position: relative;
  overflow: hidden;
}

.services__item-background:before {
  content: "";
  z-index: -1;
  background: linear-gradient(#f7eedb 0%, #dfcba3 100%);
  border-radius: 40px;
  position: absolute;
  inset: 0;
}

.services__item-header {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 16px;
  font-family: Cormorant Infant, Times New Roman, Times, serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.services__item-text {
  letter-spacing: .04em;
  width: 296px;
  margin-bottom: 32px;
  font-family: Ledger, Times New Roman, Times, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.services__item-text--special {
  margin-bottom: 65px;
}

.services__item-text--venus {
  margin-bottom: 50px;
}

.services__item-price {
  text-align: center;
  margin-bottom: 32px;
  font-family: Cormorant Infant, Times New Roman, Times, serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.services__item-arrow-wrapper {
  width: 16px;
  position: absolute;
  bottom: 8px;
  left: 157px;
  transform: rotate(180deg);
}

.services__content {
  cursor: default;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.services__consultation {
  display: block;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .services__consultation {
    visibility: hidden;
    display: none;
  }
}

.services__buttons-paginator {
  visibility: hidden;
  justify-content: space-between;
  width: 100%;
  display: none;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .services__buttons-paginator {
    visibility: visible;
    display: flex;
  }
}

.services__btn-prev {
  cursor: pointer;
  background-image: url("стрілка.5af5efd6.svg");
  background-repeat: no-repeat;
  width: 90px;
  height: 32px;
  transition: all .3s;
  transform: scale(-1);
}

.services__btn-prev:hover {
  transform: scale(-1.1);
}

.services__btn-next {
  cursor: pointer;
  background-image: url("стрілка.5af5efd6.svg");
  background-repeat: no-repeat;
  width: 90px;
  height: 32px;
  transition: all .3s;
}

.services__btn-next:hover {
  transform: scale(1.1);
}

.services__slider {
  justify-content: center;
  align-items: center;
  gap: 24px;
  height: 460px;
  display: flex;
}

.services__slider-container {
  visibility: hidden;
  width: 2405px;
  height: 460px;
  margin-bottom: 32px;
  display: none;
  position: relative;
  overflow: hidden;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .services__slider-container {
    visibility: visible;
    display: block;
  }
}

.services__slide {
  cursor: pointer;
  text-align: center;
  background: linear-gradient(#f7eedb 0%, #e0cda5 100%);
  border-radius: 20px;
  width: 208px;
  height: 234px;
  padding: 20.26px 12.07px 13.35px;
  font-family: Ledger, Times New Roman, Times, serif;
  position: relative;
}

.services__slide-header {
  letter-spacing: 0;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20.46px;
  font-family: Cormorant Infant, Times New Roman, Times, serif;
  font-size: 10px;
  font-weight: 700;
  line-height: 15px;
}

.services__slide-text {
  letter-spacing: .04em;
  text-align: left;
  padding: 0 8px;
  font-size: 8px;
  font-weight: 400;
  line-height: 12px;
}

.services__slide-price {
  letter-spacing: 0;
  text-align: left;
  font-family: Cormorant Infant, Times New Roman, Times, serif;
  font-size: 10px;
  font-weight: 700;
  line-height: 15px;
  position: absolute;
  bottom: 13.35px;
  left: 82.1px;
}

.services__pagination {
  width: 80px;
  height: 20px;
  margin: auto;
  position: relative;
  overflow: hidden;
}

.services__pagination-wrapper {
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 200px;
  height: 20px;
  display: flex;
  position: absolute;
}

.services__pagination--mobile {
  visibility: hidden;
  display: none;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .services__pagination--mobile {
    visibility: visible;
    display: block;
  }
}

.services__pagination-item {
  cursor: pointer;
  background: linear-gradient(#f7eedb 0%, #dfcba3 100%);
  border-radius: 4px;
  width: 14px;
  height: 14px;
  transition: all .5s ease-in-out;
}

.services__pagination-item--invisible {
  opacity: 0;
  cursor: default;
}

.services__pagination-item--is-active {
  background: #dfcba3;
  border-radius: 6px;
  width: 20px;
  height: 20px;
  transition: all .5s ease-in-out;
}

.consultation-button {
  text-align: center;
  letter-spacing: .04em;
  color: #2b2925;
  background-image: linear-gradient(#f7eedb 0%, #dfcba3 100%);
  border: 1px solid #0000;
  border-color: #f7eedb #0000 #dfcba3;
  border-radius: 20px;
  width: 230px;
  height: 56px;
  padding: 17px 16px 18px;
  font-family: Ledger, Times New Roman, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-decoration: none;
  transition: all .4s;
  box-shadow: 0 16px 24px -16px #3b383840;
}

.consultation-button:hover {
  transform: scale(1.1);
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .consultation-button {
    width: 302px;
    height: 64px;
    font-size: 16px;
    line-height: 24px;
  }
}

.feedbacks {
  position: relative;
}

.feedbacks__header {
  width: 100px;
  margin: 0 auto 32px;
}

.feedbacks__header-right-line, .feedbacks__header-left-line {
  top: 20.5px;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .feedbacks__header {
    width: 186px;
    margin: 0 auto 64px;
  }

  .feedbacks__header-right-line, .feedbacks__header-left-line {
    top: 41.5px;
  }
}

.feedbacks__list {
  height: 257px;
  margin-bottom: 32px;
}

.feedbacks__content {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.feedbacks__buttons-paginator {
  visibility: hidden;
  justify-content: space-between;
  width: 100%;
  display: none;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .feedbacks__buttons-paginator {
    visibility: visible;
    display: flex;
  }
}

.feedbacks__btn-prev {
  cursor: pointer;
  background-image: url("стрілка.5af5efd6.svg");
  background-repeat: no-repeat;
  width: 90px;
  height: 32px;
  transition: all .3s;
  transform: scale(-1);
}

.feedbacks__btn-prev:hover {
  transform: scale(-1.1);
}

.feedbacks__btn-next {
  cursor: pointer;
  background-image: url("стрілка.5af5efd6.svg");
  background-repeat: no-repeat;
  width: 90px;
  height: 32px;
  transition: all .3s;
}

.feedbacks__btn-next:hover {
  transform: scale(1.1);
}

.feedbacks__slider {
  justify-content: center;
  align-items: center;
  gap: 24px;
  display: flex;
}

.feedbacks__slider-container {
  visibility: hidden;
  width: 2150px;
  height: 263px;
  margin-bottom: 32px;
  display: none;
  position: relative;
  overflow: hidden;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .feedbacks__slider-container {
    visibility: visible;
    display: block;
  }
}

.feedbacks__slide {
  text-align: center;
  letter-spacing: .04em;
  cursor: pointer;
  background-image: url("border.57bcb889.svg");
  background-position: -8px;
  background-size: 428px 263px;
  width: 411px;
  height: 263px;
  padding: 24px 40px;
  font-family: Ledger, Times New Roman, Times, serif;
  font-weight: 400;
  position: relative;
}

.feedbacks__slide-name {
  font-size: 24px;
  line-height: 33px;
}

.feedbacks__slide-text {
  height: 126px;
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 21px;
}

.feedbacks__pagination {
  cursor: pointer;
  width: 80px;
  height: 20px;
  margin: auto;
  position: relative;
  overflow: hidden;
}

.feedbacks__pagination-wrapper {
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 200px;
  height: 20px;
  display: flex;
  position: absolute;
}

.feedbacks__pagination--mobile {
  visibility: hidden;
  display: none;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .feedbacks__pagination--mobile {
    visibility: visible;
    display: block;
  }
}

.feedbacks__pagination-item {
  cursor: pointer;
  background: linear-gradient(#f7eedb 0%, #dfcba3 100%);
  border-radius: 4px;
  width: 14px;
  height: 14px;
  transition: all .5s ease-in-out;
}

.feedbacks__pagination-item--invisible {
  opacity: 0;
  cursor: default;
}

.feedbacks__pagination-item--is-active {
  background: #dfcba3;
  border-radius: 6px;
  width: 20px;
  height: 20px;
  transition: all .5s ease-in-out;
}

.slider-container {
  width: 358px;
  height: 257px;
  position: relative;
  overflow: hidden;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .slider-container {
    visibility: hidden;
    display: none;
  }
}

.slider {
  width: 100%;
  height: 205px;
  margin-bottom: 32px;
  transition: transform .3s ease-in-out;
  display: flex;
  transform: translateX(-716px);
}

.slide {
  background-image: url("border.57bcb889.svg");
  background-position-x: -8px;
  flex: 0 0 100%;
  padding: 24px 16px;
}

.slide__content {
  text-align: center;
  letter-spacing: .04em;
  font-family: Ledger, Times New Roman, Times, serif;
  font-size: 14px;
  font-weight: 400;
}

.slide__feedback {
  margin-bottom: 24px;
  line-height: 21px;
}

.slide__name {
  font-size: 20px;
  line-height: 28px;
}

.pagination {
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: 20px;
  display: flex;
  overflow: hidden;
}

.pagination span {
  cursor: pointer;
  opacity: .5;
  background: linear-gradient(#f7eedb 0%, #dfcba3 100%);
  border-radius: 4px;
  width: 14px;
  height: 14px;
  transition: opacity .3s ease-in-out;
}

.pagination .active {
  opacity: 1;
  background: #dfcba3;
  border-radius: 6px;
  width: 20px;
  height: 20px;
  transition: opacity .3s ease-in-out;
}

.footer__container {
  flex-direction: column;
  align-items: center;
  padding: 24px;
  display: flex;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .footer__container {
    padding-top: 40px;
    padding-bottom: 24px;
  }
}

.footer__certificates {
  flex-direction: column;
  align-items: center;
  margin: auto;
  display: flex;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .footer__certificates {
    flex-direction: row;
    gap: 24px;
    margin-bottom: 85px;
  }
}

.footer__certificates-1 {
  cursor: default;
  border-radius: 16px;
  width: 286px;
  height: 204px;
  margin-bottom: 24px;
  box-shadow: 0 12.7821px 19.1732px -12.7821px #3b383840;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .footer__certificates-1 {
    border-radius: 23px;
    width: 411px;
    height: 294px;
    margin-bottom: 0;
  }
}

.footer__certificates-2 {
  cursor: default;
  border-radius: 16px;
  width: 231px;
  height: 164px;
  margin-bottom: 64px;
  box-shadow: 0 10.324px 15.486px -10.324px #3b383840;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .footer__certificates-2 {
    border-radius: 23px;
    width: 411px;
    height: 294px;
    margin-bottom: 0;
  }
}

.footer__logo {
  cursor: pointer;
  margin-bottom: 64px;
  text-decoration: none;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .footer__logo {
    width: 108px;
    height: 72px;
    margin-bottom: 84px;
    transition: transform .4s;
  }

  .footer__logo:hover {
    transform: scale(1.1);
  }

  .footer__logo-text {
    letter-spacing: .16em;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
  }
}

.footer__logo-image {
  cursor: pointer;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .footer__logo-image {
    width: 72px;
    height: 52px;
  }
}

.footer__contacts {
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  display: flex;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .footer__contacts {
    justify-content: space-between;
  }
}

.footer__contacts-licence {
  text-align: left;
  align-items: center;
  font-family: Ledger, Times New Roman, Times, serif;
  font-size: 8px;
  font-weight: 400;
  line-height: 11px;
  display: flex;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .footer__contacts-licence {
    letter-spacing: 0;
    text-align: left;
    margin-right: 40px;
    font-size: 16px;
    line-height: 22px;
  }
}

.footer__contacts-licence-icon {
  width: 8px;
  height: 8px;
  margin-right: 2px;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .footer__contacts-licence-icon {
    width: 22px;
    height: 22px;
  }
}

.footer__contacts-icons {
  width: 80px;
  height: 32px;
  position: relative;
}

.footer__contacts-icons-instagram {
  width: 32px;
  height: 32px;
  margin-right: 16px;
  transition: transform .4s;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .footer__contacts-icons-instagram {
    width: 40px;
    height: 40px;
  }

  .footer__contacts-icons-instagram:hover {
    transform: scale(1.1);
  }
}

.footer__contacts-icons-telegram {
  width: 32px;
  height: 32px;
  transition: transform .4s;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .footer__contacts-icons-telegram {
    width: 40px;
    height: 40px;
  }

  .footer__contacts-icons-telegram:hover {
    transform: scale(1.1);
  }

  .footer__contacts-icons {
    width: 96px;
    height: 40px;
    margin-right: 0;
  }
}

.footer__contacts-designer {
  text-align: left;
  text-transform: uppercase;
  color: #40392a;
  font-family: Ledger, Times New Roman, Times, serif;
  font-size: 8px;
  font-weight: 400;
  line-height: 11px;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .footer__contacts-designer {
    letter-spacing: 0;
    font-size: 16px;
    line-height: 22px;
    text-decoration: underline;
    transition: all .4s;
  }

  .footer__contacts-designer:hover {
    color: #987838;
  }
}

.footer__link-instagram {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .footer__link-instagram {
    width: 40px;
    height: 40px;
  }
}

.footer__link-telegram {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (width >= 900px) and (width <= 3000px) {
  .footer__link-telegram {
    width: 40px;
    height: 40px;
  }
}

/*# sourceMappingURL=index.29b72e75.css.map */

@mixin onMobile {
  @media screen and (min-width: 320px) and (max-width: 899.5px) {
    @content;
  }
}

@mixin onNotebook {
  @media screen and (min-width: 900px) and (max-width: 3000px) {
    @content;
  }
}

@mixin onHighScreen {
  @media screen and (min-height: 870px) {
    @content;
  }
}

@mixin square($size) {
  width: $size;
  height: $size;
}
.menu {
  height: 100vh;
  background-image: url("../images/fluid-art-swirl-acrylic-paint.jpg");
  background-position: -59px bottom;
  background-size: 580px 921px;
  @include onNotebook {
    display: none;
    visibility: hidden;
  }

  z-index: 1;
  background-position-y: bottom;

  &__content {
    margin-top: 28vh;
  }

  &__list {
    margin: 0 auto 80px;
    width: 108px;
    height: 172px;
    list-style: none;

    font-family: 'Cormorant Infant', 'Times New Roman', serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0.16em;
    text-align: center;
  }

  &__item {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__link {
    text-decoration: none;
    color: $c_main;
  }

  &__social-networks {
    margin: 0 auto 176px;
  }

  &__logo {
    position: absolute;
    bottom: -2px;
    right: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.logo {
  width: 83px;
  height: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__image {
    cursor: default;
    width: 56px;
    height: 40px;
  }

  &__text {
    font-family: 'Cormorant Infant', 'Times New Roman', serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.16em;
    text-align: center;
    text-transform: uppercase;
  }
}
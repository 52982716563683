@import "./utils/reset";
@import "./utils/normalize";
@import "./utils/colors";
@import "./utils/mixins";
@import "./utils/variables";
@import "./utils/animations";
@import "./utils/helpers";

@import "./blocks/page.scss";
@import "./blocks/container.scss";
@import "./blocks/grid.scss";
@import "./blocks/nav.scss";
@import "./blocks/burger-menu.scss";
@import "./blocks/header.scss";
@import "./blocks/menu.scss";
@import "./blocks/top-actions.scss";
@import "./blocks/logo.scss";
@import "./blocks/social-networks.scss";
@import "./blocks/section-header.scss";
@import "./blocks/dropdown.scss";
@import "./blocks/acquaintance.scss";
@import "./blocks/main-content.scss";
@import "./blocks/astro-help.scss";
@import "./blocks/services.scss";
@import "./blocks/consultation-button.scss";
@import "./blocks/feedbacks.scss";
@import "./blocks/slider.scss";

@import "./blocks/footer.scss";
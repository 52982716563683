.burger-menu {
  display: flex;
  flex-direction: column;
  @include square(40px);
  cursor: pointer;
  position: relative;
  padding: 8px;
  z-index: 2;

  @include onNotebook {
    display: none;
    visibility: hidden;
  }

   &__opener {
    opacity: 0;

    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;

    @include square(40px);

    &[type="checkbox"]:checked ~ span:nth-of-type(1) {
      opacity: 0;
      transition: 0.4s;
    }

    &[type="checkbox"]:checked ~ span:nth-of-type(2) {
      opacity: 1;
      transition: 0.4s;
    }

    &[type="checkbox"]:not(:checked) ~ span:nth-of-type(1) {
      opacity: 1;
      transition: 0.4s;
    }

    &[type="checkbox"]:not(:checked) ~ span:nth-of-type(2) {
      opacity: 0;
      transition: 0.4s;
    }
  }

  
  &__cross {
    position: absolute;
    background-image: url("../images/cross.svg");
    background-position: center;
    opacity: 0;

    @include square(24px)
  }

  &__burger {
    position: absolute;
    background-image: url("../images/burger.svg");
    background-position: center;
    opacity: 1;

    @include square(24px)
  }
}
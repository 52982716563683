.slider-container {
  position: relative;
  width: 358px; 
  height: 257px; 
  overflow: hidden;

  @include onNotebook {
    display: none;
    visibility: hidden;
  }
}

.slider {
  display: flex;
  width: 100%;
  height: 205px;
  transition: transform 0.3s ease-in-out;
  margin-bottom: 32px;
  transform: translateX(-716px);
}

.slide {
  flex: 0 0 100%;
  padding: 24px 16px;

  background-image: url("../images/border.svg");
  background-position-x: -8px;

  &__content {
    font-family: 'Ledger', 'Times New Roman', Times, serif;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.04em;  
  }

  &__feedback {
    line-height: 21px;
    margin-bottom: 24px;
  }

  &__name {
    font-size: 20px;
    line-height: 28px;
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  gap: 16px;
  overflow: hidden; /* Додайте цей стиль, щоб ховати зайві крапки */
}

.pagination span {
  @include square(14px);
  background: linear-gradient(180deg, #F7EEDB 0%, #DFCBA3 100%);
  border-radius: 4px;
  cursor: pointer;
  opacity: 0.5; /* Знизьте прозорість, щоб приховати зайві крапки */
  transition: opacity 0.3s ease-in-out;
}

.pagination .active {
  width: 20px;
  height: 20px;
  background: #DFCBA3;
  border-radius: 6px;
  opacity: 1; /* Збільште прозорість для активної крапки */
  transition: opacity 0.3s ease-in-out;
}
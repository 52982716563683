.top-actions {
  height: 87px;
  padding: 16px;

  @include onNotebook {
    padding: 16px 80px;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-header {
  margin: 64px auto 32px;

  &-text {
    font-family: Cormorant Infant;
    font-size: 28px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: 0.04em;

    @include onNotebook {
      font-size: 56px;
      line-height: 84px;
    }
  }

  &-left-line,
  &-right-line {
    position: absolute;
    border: 0.5px solid black;
    width: 40px;   
    
    @include onNotebook {
      width: 200px;
    }

    @media screen and (min-width: 564px) and (max-width: 899.5px) {
      width: 100px;;
    }

    @media screen and (min-width: 900px) and (max-width: 970px) {
      width: 150px;;
    }
  }

  &-left-line {
    left: 0;
  }

  &-right-line {
    right: 0;
  }
}
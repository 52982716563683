.page {
  font-family: 'Cormorant Infant', Georgia, 'Times New Roman', serif;
  scroll-behavior: smooth;
  color: $c_main;
  position: relative;

  &__body {
    background-image: url("../images/background-mobile.jpg");
    background-position: 0px 94.5vh;

    @include onNotebook {
      background-image: url("../images/background-desktop.jpg");
      background-position: 0px 100vh;
    }
  }

  &__body--with-menu {
    overflow: hidden;
  }

  &__menu {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    transform: translateX(-100%);
    transition: transform 0.4s;
  }

  &__menu--opened {
    transform: translateX(0);
  }

  &__astro-help {
    margin-bottom: 12px;

    @include onNotebook {
      margin-bottom: 120px;
    }
  }

  &__nav {
    position: absolute;
    top: 0;
    right: 0;
  }

&::-webkit-scrollbar {
  width: 0px; 
}

/* Сховати полоски прокрутки, коли елемент неактивний */
&::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/* Сховати стрілки у верхній та нижній частині полоски прокрутки */
&::-webkit-scrollbar-button:start:decrement,
&::-webkit-scrollbar-button:end:increment {
  display: none;
}

/* Сховати полоску, яка показує поточну позицію прокрутки */
&::-webkit-scrollbar-track {
  background-color: transparent;
}
}
.consultation-button {
  text-decoration: none;
  text-align: center;
  font-family: 'Ledger', 'Times New Roman', serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.04em;
  color: $c_main;

  width: 230px;
  height: 56px;

  padding: 17px 16px 18px;

  border-radius: 20px;
  border: 1px solid transparent;
  border-top-color: #f7eedb;
  border-bottom-color: #DFCBA3;

  background-image: linear-gradient(180deg, #F7EEDB 0%, #DFCBA3 100%);
  box-shadow: 0px 16px 24px -16px #3B383840;

  transition: all 0.4s;

  &:hover {
    transform: scale(1.1);
  }

  @include onNotebook {
    width: 302px;
    height: 64px;

    font-size: 16px;
    line-height: 24px;
  }
}
.feedbacks {
  position: relative;

  &__header {
    width: 100px;
    &-right-line,
    &-left-line {
      top: 20.5px;
    }

    margin: 0 auto 32px;

    @include onNotebook {
      &-right-line,
      &-left-line {
        top: 41.5px;
      }
      width: 186px;
      margin: 0 auto 64px;
    }
  }

  &__list {
    height: 257px;
    margin-bottom: 32px;
  }

  &__content {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  &__buttons-paginator {
    display: none;
    visibility: hidden;

    @include onNotebook {
      display: flex;
      visibility: visible;
    }
    justify-content: space-between;
    width: 100%;
  }

  &__btn-prev {
    background-image: url("../images/стрілка.svg");
    background-repeat: no-repeat;
    width: 90px;
    height: 32px;
    transform: scale(-1);
    cursor: pointer;

    transition: all 0.3s;

    &:hover {
      transform: scale(-1.1);
    }
  }

  &__btn-next {
    background-image: url("../images/стрілка.svg");
    height: 32px;
    cursor: pointer;
    background-repeat: no-repeat;
    width: 90px;

    transition: all 0.3s;

    &:hover {
      transform: scale(1.1);
    }
  }

  &__slider {
    display: flex;
    gap: 24px;
    justify-content: center;
    align-items: center;

    &-container {
      display: none;
      visibility: hidden;
      height: 263px;
      width: 2150px;
      position: relative;
      overflow: hidden;
      margin-bottom: 32px;

      @include onNotebook {
        display: block;
        visibility: visible;
      }
    }
  };

  &__slide {
    background-image: url("../images/border.svg");
    background-position: -8px;
    position: relative;
    padding: 24px 40px;
    background-size: 428px 263px;

    height: 263px;
    width: 411px;

    font-family: 'Ledger', 'Times New Roman', Times, serif;
    text-align: center;
    font-weight: 400;
    letter-spacing: 0.04em;
    cursor: pointer;

    &-name {
      font-size: 24px;
      line-height: 33px;
    }

    &-text {
      height: 126px;
      font-size: 16px;
      line-height: 21px;  
      margin-bottom: 24px;
    }
  }

  &__pagination {
    margin: auto;
    height: 20px;
    width: 80px;
    position: relative;
    cursor: pointer;

    overflow: hidden;

    &-wrapper {
      width: 200px;
      height: 20px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
    }

    &--mobile {
      display: none;
      visibility: hidden;

      @include onNotebook {
        display: block;
        visibility: visible;
      }
    }
    
    &-item {
      @include square(14px);
      background: linear-gradient(180deg, #F7EEDB 0%, #DFCBA3 100%);
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.5s ease-in-out;

      &--invisible {
        opacity: 0;
        cursor: default;
      }

      &--is-active {
        width: 20px;
        height: 20px;
        background: #DFCBA3;
        border-radius: 6px;
        transition: all 0.5s ease-in-out;
      }
    }
  }
}
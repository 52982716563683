.first {
  transform: translateX(642px);
  transition: all 0.4s;
}

.second {
  transform: translateX(424px);
  transition: all 0.4s;
}

.third {
  transform: translateX(-191px);
  transition: all 0.4s;
}

.thirdRight {
  transform: translateX(191px);
  transition: all 0.4s;
}

.fourth {
  transform: translateX(-423px);
  transition: all 0.4s;
}

.fifth {
  transform: translateX(-653px);
  transition: all 0.4s;
}

.firstPaginator {
  transform: translateX(0px);
  transition: all 0.4s;
}

.secondPaginator {
  transform: translateX(-30px);
  transition: all 0.4s;
}

.thirdPaginator {
  transform: translateX(-60px);
  transition: all 0.4s;
}

.fourthPaginator {
  transform: translateX(-90px);
  transition: all 0.4s;
}

.fifthPaginator {
  transform: translateX(-120px);
  transition: all 0.4s;
}

.firstFeedback {
  transform: translateX(860px);
  transition: all 0.4s;
}

.secondFeedback {
  transform: translateX(430px);
  transition: all 0.4s;
}

.thirdFeedback {
  transform: translateX(0);
  transition: all 0.4s;
}

.fourthFeedback {
  transform: translateX(-430px);
  transition: all 0.4s;
}

.fifthFeedback {
  transform: translateX(-860px);
  transition: all 0.4s;
}

.firstSlide {
  transform: translateX(460px) scale(1.97);
  transition: all 0.4s;
}

.secondSlide {
  transform: translateX(232px) scale(1.97);
  transition: all 0.4s;
}

.thirdSlide {
  transform: translateX(0) scale(1.97);
  transition: all 0.4s;
}

.fourthSlide {
  transform: translateX(-232px) scale(1.97);
  transition: all 0.4s;
}

.fifthSlide {
  transform: translateX(-460px) scale(1.97);
  transition: all 0.4s;
}

.firstSlidePrev {
  transform: translateX(86px) scale(1.44);
  transition: all 0.4s;
}

.secondSlidePrev {
  transform: translateX(-146px) scale(1.44);
  transition: all 0.4s;
}

.thirdSlidePrev {
  transform: translateX(-378px) scale(1.44);
  transition: all 0.4s;
}

.fourthSlidePrev {
  transform: translateX(-608px) scale(1.44);
  transition: all 0.4s;
}

.secondSlideNext {
  transform: translateX(606px) scale(1.44);
  transition: all 0.4s;
}

.thirdSlideNext {
  transform: translateX(378px) scale(1.44);
  transition: all 0.4s;
}

.fourthSlideNext {
  transform: translateX(146px) scale(1.44);
  transition: all 0.4s;
}

.fifthSlideNext {
  transform: translateX(-86px) scale(1.44);
  transition: all 0.4s;
}
